import { For, Show, Suspense, useContext, createSignal } from 'solid-js';
import { Button, Form, Link, safeTransition, Container, BreadcrumbItem, Heading, TextLink } from '@troon/ui';
import { Title } from '@solidjs/meta';
import { Icon } from '@troon/icons';
import { useLocation } from '@solidjs/router';
import { OldCardCtx } from '../../../../providers/card';
import { getConfigValue } from '../../../../modules/config';
import { FacilityCard } from '../../../../components/facility/card';
import { Grid } from '../../../../components/layouts/grid';
import { SupportButton } from '../../../../components/support';
import { FrequentlyAskedQuestions } from '../../../../components/faqs';
import { useUtmParams } from '../../../../providers/utm';
import { gql, mutationAction, useMutation } from '../../../../graphql';
import { Hero } from '../../../../components/hero/photo';
import { UpsellAccessSection } from '../../../../components/upsells/access';
import { LocationCard } from './components/location-card';
import type { RouteDefinition } from '@solidjs/router';
import type { FacilityCardFragment } from '../../../../graphql';
import type { ParentProps, JSX } from 'solid-js';

const maxStates = 6;

export default function OldTroonCard() {
	const card = useContext(OldCardCtx);
	const utm = useUtmParams();
	const [showAllLocations, setShowAllLocations] = createSignal(false);
	const loc = useLocation();

	const optOutAction = useMutation(optOut);

	return (
		<>
			<Suspense>
				<Title>{card()?.card.group.name} | Troon Rewards</Title>
			</Suspense>

			<Hero
				src={`https://${getConfigValue('MAP_HOST')}/assets/images/troon-north.jpg`}
				breadcrumbs={() => (
					<>
						<BreadcrumbItem href="/card">2024 Troon Card</BreadcrumbItem>
						<BreadcrumbItem href={loc.pathname}>{card()?.card.group.name}</BreadcrumbItem>
					</>
				)}
			>
				<h1 class="text-3xl font-semibold leading-tight sm:text-4xl sm:leading-snug lg:text-5xl lg:leading-snug">
					{card()?.card.group.name}
				</h1>
				<p class="text-base">Explore and book discounted tee times at participating Troon courses.</p>
			</Hero>

			<Container>
				<div class="relative -top-24 z-30 -mb-12 rounded border border-red-500 bg-red-100 p-4 shadow-lg md:p-6">
					<p class="text-xl font-semibold text-red-600">
						<Icon name="circle-warning" /> All Troon Cards expire on Dec 31st.{' '}
						<TextLink href="/access" class="text-red-600 underline">
							Subscribe to Troon Access
						</TextLink>{' '}
						to continue getting discounted tee times at your favorite Troon courses
					</p>
				</div>
			</Container>

			<Container class="flex flex-col gap-8 md:gap-16">
				<Show when={utm().campaign === 'old-troon-card'}>
					<div class="flex flex-wrap justify-start gap-4 rounded bg-brand-100 p-4 text-brand-600 sm:gap-2 lg:flex-nowrap lg:items-center lg:justify-stretch">
						<Icon name="logo-square" class="size-8 shrink-0" />
						<p class="grow">Welcome to our new and improved booking experience for Troon Cardholders.</p>
						<Form action={optOutAction} class="shrink">
							<input
								type="hidden"
								name="slug"
								value={`troon-card-${card()?.card.group.id}${utm()?.content?.includes('-single') ? '-single' : ''}`}
							/>
							<Button type="submit" appearance="transparent" class="relative -start-4 shrink normal-case lg:start-0">
								Use old booking experience
								<Icon name="arrow-right-md" />
							</Button>
						</Form>
					</div>
				</Show>

				<UpsellAccessSection location={card()?.card.group.name ?? '2024 Troon Card'}>
					<Heading as="h2">Better benefits, more access.</Heading>
					<p>
						The Troon Card has evolved into Troon Access! Enjoy exclusive benefits at over 150+ Troon courses including
						Kapalua, Troon North, and Gamble Sands.
					</p>

					<div class="flex flex-wrap justify-start gap-4">
						<Button class="sm:w-fit sm:shrink sm:grow-0" as={Link} href="/access">
							Learn more<span class="sr-only"> about Troon Access</span>
						</Button>
						<Button
							class="sm:w-fit sm:shrink sm:grow-0"
							appearance="transparent-current"
							as={Link}
							href="/access/troon-card-vs-troon-access"
						>
							<Icon name="info" /> Troon Card vs. Troon Access
						</Button>
					</div>
				</UpsellAccessSection>

				<Section>
					<h2 class="text-xl font-semibold md:text-2xl">Courses by location</h2>
					<ul class="mb-4 grid snap-x grid-cols-2 gap-4 overflow-auto sm:grid-cols-3 md:flex-wrap md:overflow-hidden lg:grid-cols-6">
						<For each={showAllLocations() ? card()?.card.states : card()?.card.states.slice(0, maxStates)}>
							{(state) => (
								<li
									// eslint-disable-next-line tailwindcss/no-arbitrary-value
									class="aspect-[16/10] min-w-32 shrink grow snap-start md:min-w-0"
								>
									<LocationCard state={state} cardGroupId={card()!.card.group.id} />
								</li>
							)}
						</For>
					</ul>
					<Show when={!showAllLocations() && (card()?.card.states.length ?? 0) > maxStates}>
						<div class="flex items-center justify-center">
							<Button
								appearance="secondary"
								class="shrink grow-0"
								onClick={() => {
									safeTransition(() => {
										setShowAllLocations(true);
									});
								}}
							>
								See all locations
							</Button>
						</div>
					</Show>
				</Section>

				<Show when={card()?.card.stateFacilities}>
					{(state) => (
						<Section>
							<SectionHeader
								title={
									<>
										{state().state.name} course{state().facilities.length !== 1 ? 's' : ''}
									</>
								}
							>
								<Button
									as={Link}
									href={`/card/${card()?.card.group.id}/courses/${state().state.id}`}
									appearance="transparent"
									class="hidden md:block"
								>
									See all <span class="sr-only">{state().state.name} courses</span> <Icon name="chevron-right" />
								</Button>
							</SectionHeader>
							<ul class="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
								<For each={state().facilities.slice(0, 3) as Array<FacilityCardFragment>}>
									{(facility) => (
										<li>
											<FacilityCard facility={facility} linkToTeeTimes />
										</li>
									)}
								</For>
							</ul>
							<div class="flex items-center justify-center md:hidden">
								<div>
									<Button
										as={Link}
										href={`/card/${card()?.card.group.id}/courses/${state().state.id}`}
										appearance="secondary"
									>
										See all <span class="sr-only">{state().state.name} courses</span> <Icon name="chevron-right" />
									</Button>
								</div>
							</div>
						</Section>
					)}
				</Show>

				<Grid>
					<div class="col-span-1 lg:col-span-5">
						<h2 class="mb-4 text-3xl font-semibold md:text-4xl">Frequently asked questions</h2>
						<p class="mb-6 max-w-md">Got questions? We’ve got answers.</p>
						<div>
							<SupportButton class="shrink" appearance="secondary">
								Contact support
							</SupportButton>
						</div>
					</div>

					<div class="col-span-1 rounded border border-neutral lg:col-span-7">
						<FrequentlyAskedQuestions id="2024-troon-card-faqs" />
					</div>
				</Grid>
			</Container>
		</>
	);
}

function SectionHeader(props: ParentProps<{ title: JSX.Element }>) {
	return (
		<div class="flex flex-row flex-wrap items-center justify-between">
			<h2 class="text-xl font-semibold md:text-2xl">{props.title}</h2>
			<div>{props.children}</div>
		</div>
	);
}

function Section(props: ParentProps) {
	return <div class="flex flex-col gap-4">{props.children}</div>;
}

const optOutMutation = gql(`
	mutation optOutFacilityRedirect($slug: String!) {
		persistFacilityRedirect(facilitySlug: $slug, variation: "control") {
			redirectUrl
		}
	}`);

const optOut = mutationAction(optOutMutation, {
	onSuccess: async (res) => {
		if (res) {
			window.location.href = res.persistFacilityRedirect.redirectUrl;
		}
	},
});

export const route = { info: { hasHero: true } } satisfies RouteDefinition;
